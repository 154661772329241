import auth from '@/middleware/auth.js'

export default [
  {
    path: ''
    ,component: () => import('@/layouts/Constelacion')
    ,children: [
      {
        path: '/valija'
        ,name: 'valija'
        ,component: () => import('@/apps/valija/Dashboard')
        ,meta: {
          title: 'Valijas'
          ,middleware: auth
        }
      },
      {
        path: '/valija/solicitudes'
        ,name: 'valija_solicitudes'
        ,component: () => import('@/apps/valija/pages/Solicitud')
        ,meta: {
          title: 'Solicitudes - Valijas'
          ,middleware: auth
        }
      },
      {
        path: '/valija/configuracion'
        ,name: 'valija_configuracion'
        ,component: () => import('@/apps/valija/pages/Configuracion')
        ,meta: {
          title: 'Configuración - Valijas'
          ,middleware: auth
        }
      },
      {
        path: '/valija/catalogos'
        ,name: 'valija_catalogos'
        ,component: () => import('@/apps/valija/pages/Catalogo')
        ,meta: {
          title: 'Catálogo - Valijas'
          ,middleware: auth
        }
      }
    ]
  }
]